import React from 'react'

import IcsJobList from './IcsJobList'
// APIs:
import CustomsIcsShipmentApi from '../../reactive/apis/customs/CustomsIcsShipmentApi'
import CustomsSubmissionApi from '../../reactive/apis/customs/CustomsSubmissionApi'
// Helpers:
import showErrors from '../showErrors'

// Used on the Show Groupage screen to represent a (potential?) ICS Shipment.
export default class extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // This may be `null` if this is a "potential" ICS shipment:
      icsShipment: props.icsShipment
    }
  }

  handleSubmit = () => {
    const groupageId = this.props.groupageId
    const jobIds = this.props.jobs.map(job => job.id)

    // Make sure that the user knows what is about to happen!
    const prompt = ['Submit the following jobs?', '']
    this.props.jobs.forEach(job => prompt.push(`• ${job.jobReference}`))
    if (!window.confirm(prompt.join('\n'))) { return }

    CustomsSubmissionApi.submitIcsShipment(groupageId, jobIds).then(
      json => this.setState({ icsShipment: json }),
      json => showErrors(json.errors || [json.error])
    )
  }

  handlePdf = () => {
    CustomsIcsShipmentApi.downloadPdf(this.state.icsShipment?.id).then(
      json => {
        // Switch to the Attachments tab...
        $('a[href="#tab-attachments"]').click()
        // ...and refresh its contents:
        $('.js-file-home a').click()
      },
      json => showErrors(json.errors || [json.error])
    )
  }

  problemIcon = () => {
    if (this.props.problem) {
      return (
        <i
          className='fa fa-exclamation-triangle text-danger'
          title={this.props.problem}
        />
      )
    } else if (this.props.ambiguous) {
      return (
        <i
          className='fa fa-exclamation-triangle text-warning'
          title='This ICS record will be split upon resubmission'
        />
      )
    } else { return null }
  }

  render () {
    return (
      <div className='ics-shipment'>
        <div className='ics-reference'>{this.state.icsShipment?.reference}</div>
        <div className='ics-mrn'>{this.state.icsShipment?.mrn}</div>
        <div className='ics-status'>{this.state.icsShipment?.status}</div>
        <div className='ics-problem'>{this.problemIcon()}</div>
        <div className='ics-loading'>{this.props.placeOfLoading}</div>
        <div className='ics-offload'>{this.props.placeOfOffload}</div>
        <div className='ics-details'>{this.state.icsShipment?.details}</div>
        <div className='ics-actions'>
          <button
            className='ics-pdf-button' disabled={!this.state.icsShipment}
            onClick={this.handlePdf}
          >PDF</button>
          <button
            className='ics-submit-button' disabled={!!this.props.problem}
            onClick={this.handleSubmit}
          >Upload</button>
        </div>
        <div className='ics-jobs'>
          <IcsJobList
            icsShipment={this.props.icsShipment}
            jobs={this.props.jobs}
            previousJobs={this.props.previousJobs}
          />
        </div>
      </div>
    )
  }
}

import React from 'react'

// APIs:
import CustomsIcsShipmentApi from '../../reactive/apis/customs/CustomsIcsShipmentApi'
// Helpers:
import showErrors from '../showErrors'

// Show a list of ICS Records (so that we can delete them).
export default class extends React.Component {
  constructor (props) {
    super(props)

    // Store the list in state so that we can remove them as we go:
    this.state = { records: props.records }
  }

  handleDelete = (id) => {
    if (!confirm('Delete this ICS record?')) { return }

    CustomsIcsShipmentApi.destroy(id).then(
      () => {
        const records = this.state.records.filter(ics => ics.id !== id)
        this.setState({ records: records })
      },
      json => showErrors(json.errors || [json.error])
    )
  }

  render () {
    // If we don't need to delete any records, don't show anything:
    if (!this.state.records.length) { return null }

    const rows = this.state.records.map(record => (
      <div key={record.id} className='unused-ics-record'>
        <div className='unused-ics-record-reference'>{record.reference}</div>
        <div className='unused-ics-record-mrn'>{record.mrn}</div>
        <button
          className='unused-ics-record-delete'
          onClick={e => this.handleDelete(record.id)}
        >Delete</button>
      </div>
    ))

    return (
      <div className='alert alert-danger'>
        <i className='fa fa-exclamation-triangle'
        /> The following ICS records no longer have any associated jobs: {rows}
      </div>
    )
  }
}

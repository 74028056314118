import BaseApi from '../BaseApi'

// This class handles all API interactions related to ICS Shipments.
class CustomsIcsShipmentApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/customs/ics_shipments')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/customs/ics_shipments/${id}`)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/customs/ics_shipments/${id}.json`)
  }

  static downloadPdf (id) {
    return BaseApi.sendRequest('POST', `api/customs/ics_shipments/${id}/download_pdf`)
  }
}

export default CustomsIcsShipmentApi

import React from 'react'

// This simply displays a list of jobs, with icons to indicate addition/removal.
export default function (props) {
  const icsShipmentId = props.icsShipment?.id || null
  const jobIds = props.jobs.map(job => job.id)
  const omissions = props.previousJobs.filter(job => !jobIds.includes(job.id))

  const reassignmentWarning = (
    <i
      className='fa fa-warning text-warning'
      title='This job was not previously assigned to this group'
    />
  )

  const jobs = props.jobs.map(job => (
    <span key={job.id}>
      {job.icsShipmentId === icsShipmentId ? null : reassignmentWarning}
      <a href={`/jobs/${job.id}`} target='_blank'>{job.jobReference}</a>
    </span>
  ))

  const missingJobs = omissions.map(job => (
    <span key={job.id}>
      <del className='text-danger'>
        <a href={`/jobs/${job.id}`} target='_blank'>{job.jobReference}</a>
      </del>
    </span>
  ))

  return <div className='ics-job-list'>{jobs}{missingJobs}</div>
}
